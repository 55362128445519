/* eslint-disable handle-callback-err */
/* eslint-disable no-throw-literal */
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { multiEntityActions, multiEntityGetters, MultiEntityLoadingStates, multiEntityMutations, multiEntityState } from '../mixins/loading-states';
import Vue from 'vue';
import { RootState } from '../types';

interface AdminState extends MultiEntityLoadingStates {
  token: string | null,
  noAccessURL: string | null,
  companyInformation: string | null,
  companyId: number | string | null,
  activities: string[] | null,
  employees: string[] | null
}

const namespaced: boolean = true;
const state: AdminState = {
  ...multiEntityState,
  token: null,
  noAccessURL: process.env.VUE_APP_NOACCESSURL,
  companyInformation: null,
  companyId: null,
  activities: null,
  employees: null
};

const actions: ActionTree<AdminState, RootState> = {
  ...multiEntityActions,
  validateToken ({ dispatch, commit, state }) {
    if (process.env.NODE_ENV === 'development' && state.token === 'test') {
      commit('setCompanyId', 402403);
      return dispatch('loadCompanyInformation');
    }
    return dispatch('handleLoadingState', {
      entity: 'validateToken',
      promise: Vue.axios.get(state.noAccessURL + 'token-validation.php?Token=' + state.token)
    })
      .then((result) => {
        commit('setCompanyId', result.data);
        return dispatch('loadCompanyInformation');
      })
      // eslint-disable-next-line n/handle-callback-err
      .catch(() => {
        throw 'ERROR';
      });
  },
  loadCompanyInformation ({ dispatch, commit, state }) {
    const t = Date.now();
    const timestampQuery = '&time=' + t;

    return dispatch('handleLoadingState', {
      entity: 'companyInfo',
      promise: Vue.axios.get(
        state.noAccessURL + 'getAppointment.php?action=personalize&companyId=' + state.companyId + timestampQuery
      )
    }).then((result) => {
      commit('setCompanyInfo', result.data[0]);
    });
  },
  loadActivities ({ dispatch, commit, state }) {
    const t = Date.now();
    const timestampQuery = '&time=' + t;
    return dispatch('handleLoadingState', {
      entity: 'activities',
      promise: Vue.axios.get(
        state.noAccessURL + 'getAppointment.php?action=activities&companyId=' + state.companyId + timestampQuery
      )
    }).then((result) => {
      // @TODO: convert 'any' to Activity object?
      commit('setActivities', result.data.map((el: any) => {
        el.Description = !el.Description ? '' : el.Description;
        return el;
      }));
    });
  },
  loadEmployees ({ dispatch, commit, state }) {
    const t = Date.now();
    const timestampQuery = '&time=' + t;
    return dispatch('handleLoadingState', {
      entity: 'employees',
      promise: Vue.axios.get(
        state.noAccessURL + 'getAppointment.php?action=employees&companyId=' + state.companyId + timestampQuery
      )
    }).then((result) => {
      commit('setEmployees', result.data.map((el: any) => {
        el.Description = !el.Description ? '' : el.Description;
        return el;
      }));
    });
  },
  saveData ({ dispatch, state }, data) {
    if (data.fieldDOBshow === false) {
      data.fieldDOBmandatory = false;
    }

    const formData = new FormData();

    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value as string);
    }

    return dispatch('handleLoadingState', {
      entity: 'saveData',
      promise: Vue.axios.post(state.noAccessURL + 'updateUserData.php?Token=' + state.token, formData)
    });
  },
  uploadLogo ({ dispatch, state }, image) {
    const formData = new FormData();
    formData.append('image', image, state.companyId + '-Logo.jpg');
    formData.append('companyID', state.companyId as string);

    return dispatch('handleLoadingState', {
      entity: 'uploadLogo',
      promise: Vue.axios.post(state.noAccessURL + 'uploadLogo.php?Token=' + state.token, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    });
  },
  uploadLanding ({ dispatch, state }, image) {
    const formData = new FormData();
    formData.append('image', image, state.companyId + '-Landing.jpg');
    formData.append('companyID', state.companyId as string);

    return dispatch('handleLoadingState', {
      entity: 'uploadLanding',
      promise: Vue.axios.post(state.noAccessURL + 'uploadLogo.php?Token=' + state.token, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    });
  },
  uploadPP ({ dispatch, state }, pp) {
    const formData = new FormData();
    formData.append('file', pp, state.companyId + '-privacypolicy.pdf');
    formData.append('companyID', state.companyId as string);

    return dispatch('handleLoadingState', {
      entity: 'uploadPP',
      promise: Vue.axios.post(state.noAccessURL + 'uploadPP.php?Token=' + state.token, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    });
  },
  updateActivity ({ dispatch, state }, formData) {
    return dispatch('handleLoadingState', {
      entity: 'updateActivity',
      promise: Vue.axios.post(state.noAccessURL + 'updateActivityData.php?Token=' + state.token, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    });
  },
  updateEmployee ({ dispatch, state }, formData) {
    return dispatch('handleLoadingState', {
      entity: 'updateEmployee',
      promise: Vue.axios.post(state.noAccessURL + 'updateEmployeeData.php?Token=' + state.token, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    });
  },
  deleteEmployeeImage ({ dispatch, state }, formData) {
    return dispatch('handleLoadingState', {
      entity: 'deleteEmployeeImage',
      promise: Vue.axios.post(state.noAccessURL + 'deleteEmployeeImage.php?Token=' + state.token, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    });
  },
  uploadEmployeeImage ({ dispatch, state }, formData) {
    return dispatch('handleLoadingState', {
      entity: 'uploadEmployeeImage',
      promise: Vue.axios.post(state.noAccessURL + 'uploadEmployeeImage.php?Token=' + state.token, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    });
  },
  logout () { }
};

const mutations: MutationTree<AdminState> = {
  ...multiEntityMutations,
  setToken (state, token) {
    state.token = token;
  },
  setCompanyInfo (state, companyInformation) {
    state.companyInformation = companyInformation;
  },
  setCompanyId (state, companyId) {
    state.companyId = companyId;
  },
  setActivities (state, activities) {
    state.activities = activities;
  },
  setEmployees (state, employees) {
    state.employees = employees;
  },
  moveActivities (state, payload) {
    state.activities = payload;
  },
  moveEmployees (state, payload) {
    state.employees = payload;
  }
};

const getters: GetterTree<AdminState, RootState> = {
  ...multiEntityGetters,
  token: (state) => state.token,
  companyInformation: (state) => state.companyInformation,
  companyId: (state) => state.companyId,
  activities: (state) => state.activities,
  employees: (state) => state.employees
};

export const adminModule: Module<AdminState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
  getters
};
