import Vue from 'vue';
import VueRouter, { RouteConfig, Location } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'HomeView',
    component: () => import('../components/HomeView.vue')
  },
  {
    path: '/:otevurl',
    name: 'IntroView',
    component: () => import('../components/IntroView.vue')
  },
  {
    path: '/:otevurl/appointment/start',
    name: 'MainPage',
    component: () => import('../pages/MainPage.vue'),
    props: true
  },
  {
    path: '/admin',
    name: 'AdminPage',
    component: () => import('../pages/AdminPage.vue'),
    props: true,
    children: [
      {
        name: 'DashboardView',
        path: 'dashboard',
        component: () => import('../components/DashboardView.vue'),
        props: true
      },
      {
        name: 'EmployeesEditView',
        path: 'employees',
        component: () => import('../components/EmployeesEditView.vue'),
        props: true
      },
      {
        name: 'ActivitiesEditView',
        path: 'activities',
        component: () => import('../components/ActivitiesEditView.vue'),
        props: true
      },
      {
        name: 'ErrorView',
        path: '401',
        component: () => import('../components/ErrorView.vue'),
        props: true
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach((to, from, next) => {
  // If there is "ver" parameter in the query and it's "v2" then put token to localstorage
  // and load the destination page without token in the query.
  if (to.query.ver === 'v2' && to.query.token) {
    localStorage.setItem('token', to.query.token.toString());

    const query = to.query;
    delete query.token;
    // Build Location object and use as the next direction without token parameter.
    const location: Location = {
      path: to.path,
      hash: to.hash,
      query,
      params: to.params
    };
    next(location);
  } else {
    next();
  }
});

export default router;
