import Vue from 'vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import App from './App.vue';
import router from './router';
import store from './store';
// import VueResource from 'vue-resource';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import VModal from 'vue-js-modal';
import Meta from 'vue-meta';
import './assets/styles.css';
import './assets/icons.css';
import VueLocalStorage from 'vue-localstorage';

import { TableComponent, TableColumn } from 'vue-table-component';

import moment from 'moment';
import VueMomentJS from 'vue-momentjs';

import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(VueAxios, axios);

interface Navigator {
  userLanguage?: string;
  language: string;
}

const navigator: Navigator = window.navigator;

Vue.axios.get('/translations.json').then((result) => {
  const translations = result.data;

  const preferredLanguage = (navigator.userLanguage || navigator.language);
  const [lang] = (preferredLanguage ?? '')
    .replace('-', '_')
    .toLowerCase()
    .split('_');

  Vue.prototype.$language = lang || 'en';
  Vue.prototype.$translations = translations[Vue.prototype.$language];

  Vue.config.productionTip = false;

  Vue.use(BootstrapVue);
  // @TODO: VueResource conflicts with axios. It's used in the MainVue (this.$http.post()), refactor to use axios?
  // Vue.use(VueResource);
  Vue.use(VueMomentJS, moment);
  Vue.use(VueLocalStorage);
  Vue.use(Meta);
  Vue.use(VModal, { dynamic: true, dynamicDefaults: { clickToClose: false }, injectModalsContainer: true });
  Vue.use(BootstrapVueIcons);

  Vue.component('table-component', TableComponent);
  Vue.component('table-column', TableColumn);

  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app');
});
