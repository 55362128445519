import _ from 'lodash';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '../types';

/**
 * To use this mixin please use the spread operator to extend your state, mutations, actions, getters
 *
 * import { multiEntityLoading } from '../mixins/loading-states';
 * const Module = {
 *    namespaced: true,
 *    state: {
 *        ...multiEntityLoading.state,
 *        ...,
 *    },
 *    mutations: {
 *        ...multiEntityLoading.mutations,
 *        ...,
 *    },
 * };
 */

const loadingStatus = {
  NOT_LOADED: 0,
  LOADING: 1,
  LOADED: 2
};

// SingleEntityLoading mixin.

export interface SingleEntityLoadingStates {
  isLoading: number;
}

export const singleEntityLoadingState: SingleEntityLoadingStates = {
  isLoading: loadingStatus.NOT_LOADED
};

export const singleEntityActions: ActionTree<SingleEntityLoadingStates, RootState> = {};

export const singleEntityMutations: MutationTree<SingleEntityLoadingStates> = {
  setLoading (state) {
    state.isLoading = loadingStatus.LOADING;
  },
  setNotLoaded (state) {
    state.isLoading = loadingStatus.NOT_LOADED;
  },
  setLoaded (state) {
    state.isLoading = loadingStatus.LOADED;
  }
};

export const singleEntityGetters: GetterTree<SingleEntityLoadingStates, RootState> = {
  isNotLoaded: (state) => state.isLoading === loadingStatus.NOT_LOADED,
  isLoading: (state) => state.isLoading === loadingStatus.LOADING,
  isLoaded: (state) => state.isLoading === loadingStatus.LOADED
};

export const singleEntityStates: Module<SingleEntityLoadingStates, RootState> = {
  state: singleEntityLoadingState,
  mutations: singleEntityMutations,
  actions: singleEntityActions,
  getters: singleEntityGetters
};

// MultiEntityLoading mixin.

type LoadingStateType = {
  [key: string]: number
}

export interface MultiEntityLoadingStates {
  isLoading: LoadingStateType
};

export const multiEntityState: MultiEntityLoadingStates = {
  isLoading: {}
};

export const multiEntityActions: ActionTree<MultiEntityLoadingStates, RootState> = {
  handleLoadingState ({ commit }, { entity, promise }) {
    commit('setLoading', entity);
    return new Promise((resolve, reject) => {
      promise
        .then((response: any) => {
          commit('setLoaded', entity);
          resolve(response);
        })
        .catch((error: any) => {
          commit('setNotLoaded', entity);
          reject(error);
        });
    });
  }
};

export const multiEntityMutations: MutationTree<MultiEntityLoadingStates> = {
  resetLoadingState (state) {
    state.isLoading = {};
  },
  setLoading (state, entity) {
    state.isLoading = _.assign({}, state.isLoading, {
      [entity]: loadingStatus.LOADING
    });
  },
  setLoaded (state, entity) {
    state.isLoading = _.assign({}, state.isLoading, {
      [entity]: loadingStatus.LOADED
    });
  },
  setNotLoaded (state, entity) {
    state.isLoading = _.assign({}, state.isLoading, {
      [entity]: loadingStatus.NOT_LOADED
    });
  }
};

export const multiEntityGetters: GetterTree<MultiEntityLoadingStates, RootState> = {
  isNotLoaded: (state) => (entity: string) => !state.isLoading[entity], // if it is 0 or unset
  isLoading: (state) => (entity: string) => state.isLoading[entity] === loadingStatus.LOADING,
  isLoaded: (state) => (entity: string) => state.isLoading[entity] === loadingStatus.LOADED
};

export const multiEntityStates: Module<MultiEntityLoadingStates, RootState> = {
  actions: multiEntityActions,
  mutations: multiEntityMutations,
  getters: multiEntityGetters,
  state: multiEntityState
};
