const obj = {
  getDates (startDate: Date, endDate: Date) {
    const dates = [];
    let currentDate = startDate;
    const addDays = function (this: Date, days: number) {
      const date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };

    while (currentDate <= endDate) {
      dates.push(currentDate);
      currentDate = addDays.call(currentDate, 1);
    }

    return dates;
  },
  loadSlots (roomAv: Array<any>, employeeAv: Array<any>, companyInformation: any, duration: number, selectedDate: Date) {
    const d3 = require('d3');
    const moment = require('moment');
    const time = [];
    let Estart;
    let Eend;
    let Rstart;
    let Rend;
    let EmployeeIDOfInterval = 0;
    let roundedStart;
    let date;
    const slotpitch = companyInformation.SlotPitch;
    const x = employeeAv.length;
    const y = roomAv.length;
    let i;
    let j;
    const coeff = 1000 * 60 * parseInt(companyInformation.SlotPitch);
    for (i = 0; i < x; i++) {
      date = moment(employeeAv[i].AvailStart).locale('de');
      roundedStart = moment(Math.ceil(date.valueOf() / coeff) * coeff);

      Estart = moment(roundedStart);
      Eend = moment(employeeAv[i].AvailEnd).locale('de'); // End mussend be roundet because the Timeslots get splitet into Slots of the Duration lenght
      EmployeeIDOfInterval = employeeAv[i].EmployeeID;

      for (j = 0; j < y; j++) {
        const dater = moment(roomAv[j].AvailStart).locale('de');
        const roundedStartR = new Date(Math.ceil(dater.valueOf() / coeff) * coeff);

        Rstart = moment(roundedStartR);
        Rend = moment(roomAv[j].AvailEnd).locale('de'); // End mussend be roundet because the Timeslots get splitet into Slots of the Duration lenght if needed can be done with Math.floor()

        // eslint-disable-next-line no-empty
        if (Estart.valueOf() < Rstart.valueOf() && Eend.valueOf() < Rstart.valueOf()) {
        // eslint-disable-next-line no-empty
        } else if (Estart.valueOf() >= Rstart.valueOf() && Estart.valueOf() >= Rend.valueOf()) {
        } else if (Estart.valueOf() <= Rstart.valueOf() && Eend.valueOf() <= Rend.valueOf()) {
          time.push({
            Starthour: Rstart.format('HH'),
            Startmin: Rstart.format('mm'),
            Endhour: Eend.format('HH'),
            Endmin: Eend.format('mm'),
            EmployeeID: EmployeeIDOfInterval,
            RoomID: roomAv[j].RoomID
          });
        } else if (Estart.valueOf() <= Rstart.valueOf() && Eend.valueOf() >= Rend.valueOf()) {
          time.push({
            Starthour: Rstart.format('HH'),
            Startmin: Rstart.format('mm'),
            Endhour: Rend.format('HH'),
            Endmin: Rend.format('mm'),
            EmployeeID: EmployeeIDOfInterval,
            RoomID: roomAv[j].RoomID
          });
        } else if (Estart.valueOf() >= Rstart.valueOf() && Eend.valueOf() >= Rend.valueOf()) {
          time.push({
            Starthour: Estart.format('HH'),
            Startmin: Estart.format('mm'),
            Endhour: Rend.format('HH'),
            Endmin: Rend.format('mm'),
            EmployeeID: EmployeeIDOfInterval,
            RoomID: roomAv[j].RoomID
          });
        } else if (Estart.valueOf() >= Rstart.valueOf() && Eend.valueOf() <= Rend.valueOf()) {
          time.push({
            Starthour: Estart.format('HH'),
            Startmin: Estart.format('mm'),
            Endhour: Eend.format('HH'),
            Endmin: Eend.format('mm'),
            EmployeeID: EmployeeIDOfInterval,
            RoomID: roomAv[j].RoomID
          });
        }
      }
    }

    const slot = [];
    for (i = 0; i < time.length; i++) {
      const Start = new Date(
        moment(selectedDate).year(),
        moment(selectedDate).month(),
        moment(selectedDate).day() + 1,
        time[i].Starthour,
        time[i].Startmin
      );
      const End = new Date(
        moment(selectedDate).year(),
        moment(selectedDate).month(),
        moment(selectedDate).day() + 1,
        time[i].Endhour,
        time[i].Endmin
      );
      const slot1 = d3.timeMinute.range(Start, End, slotpitch);

      for (let s = 0; s < slot1.length; s++) {
        const count = d3.timeMinute.count(slot1[s], End);

        if (count >= duration) {
          const dd = moment(selectedDate);
          dd.set({
            hour: moment(slot1[s]).hour(),
            minute: moment(slot1[s]).minute()
          });
          const ddFormat = dd.format('DD-MM-YYYY HH:mm');

          let add = true;

          for (let k = 0; k < slot.length; k++) {
            if (slot[k].Date === dd.format('DD-MM-YYYY HH:mm')) add = false;
          }
          if (add) {
            slot.push({
              Date: ddFormat,
              CompareDate: slot1[s],
              Index: slot.length,
              StartTime: moment(slot1[s]).format('HH:mm'),
              EndTime: moment(moment(slot1[s]).add(duration, 'minutes')).format('HH:mm'),
              EmployeeID: time[i].EmployeeID,
              RoomID: time[i].RoomID
            });
          }
        }
      }
    }

    slot.sort(function (a, b) {
      return a.CompareDate - b.CompareDate;
    });

    const times = slot.filter((el, i, a) => i === a.indexOf(el));

    return times;
  }
};

export default obj;
