import { multiEntityActions, multiEntityGetters, MultiEntityLoadingStates, multiEntityMutations, multiEntityState } from '../mixins/loading-states';
import Vue from 'vue';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '../types';

const namespaced: boolean = true;

interface RoomAvailability {
  AvailStart: string,
  AvailEnd: string,
  EmployeeID: string | number,
}

interface EmployeeAvailability {
  AvailStart: string,
  AvailEnd: string,
  EmployeeID: string | number,
}

interface MonthDateItem {
  employeeAv: EmployeeAvailability[],
  roomAv: RoomAvailability[],
}

interface MonthDate {
  [key: string]: MonthDateItem
}

interface AppointmentState extends MultiEntityLoadingStates {
  noAccessURL: string,
  monthDates: MonthDate,
  roomAv: RoomAvailability[],
  employeeAv: EmployeeAvailability[],
  disabledDatesForMonth: string[]
}

const state: AppointmentState = {
  ...multiEntityState,
  noAccessURL: process.env.VUE_APP_NOACCESSURL,
  monthDates: {},
  roomAv: [],
  employeeAv: [],
  disabledDatesForMonth: []
};

const mutations: MutationTree<AppointmentState> = {
  ...multiEntityMutations,
  addDatesForMonth (state, { data, key }) {
    Vue.set(state.monthDates, key, data);
  },
  setRoomAv (state, { day, month, year }) {
    state.roomAv = state.monthDates[year + '/' + (month + 1)].roomAv.filter((el) => {
      let monthString = month + 1;
      let dayString = day;

      if (month + 1 < 10) monthString = '0' + (month + 1);
      if (day < 10) dayString = '0' + day;

      return el.AvailStart.includes(`${year}-${monthString}-${dayString}`);
    });
  },
  setEmployeeAv (state, { day, month, year }) {
    state.employeeAv = state.monthDates[year + '/' + (month + 1)].employeeAv.filter((el) => {
      let monthString = month + 1;
      let dayString = day;

      if (month + 1 < 10) monthString = '0' + (month + 1);
      if (day < 10) dayString = '0' + day;
      return el.AvailStart.includes(`${year}-${monthString}-${dayString}`);
    });
  },
  resetMonthDates (state) {
    state.monthDates = {};
  },
  setDisabledDatesForMonth (state, { year, month, companyInformation, duration }) {
    const calc = require('@/js/slot-calc.ts').default;
    const startDate = new Date(year, month, 1, 12);
    const endDate = new Date(year, month + 1, 0, 12);

    const range = calc.getDates(startDate, endDate);
    const currentMonthDates = state.monthDates[year + '/' + (month + 1)];

    const disabledDates = [];
    for (let i = 0; i < range.length; i++) {
      const dd = range[i];
      const eAv = currentMonthDates.employeeAv.filter((el) => {
        let monthString = month + 1;
        let dayString = dd.getDate();

        if (monthString < 10) monthString = '0' + (month + 1);
        if (dayString < 10) dayString = '0' + dayString;

        return el.AvailStart.includes(`${year}-${monthString}-${dayString}`);
      });

      const rAv = currentMonthDates.roomAv.filter((el) => {
        let monthString = month + 1;
        let dayString = dd.getDate();

        if (monthString < 10) monthString = '0' + (month + 1);
        if (dayString < 10) dayString = '0' + dayString;

        return el.AvailStart.includes(`${year}-${monthString}-${dayString}`);
      });

      const slots = calc.loadSlots(rAv, eAv, companyInformation, duration, dd);

      if (slots.length === 0) {
        disabledDates.push(dd);
      }
    }

    state.disabledDatesForMonth = disabledDates;
  }
};

const actions: ActionTree<AppointmentState, RootState> = {
  ...multiEntityActions,
  cacheAppointmentsForMonth ({ dispatch, commit, state, getters }, { formData, month, year }) {
    if (state.monthDates[year + '/' + month] || getters.isLoading('RoomAvEmpAv')) {
      return;
    }
    formData.append('month', month);
    formData.append('year', year);
    return dispatch('handleLoadingState', {
      entity: 'RoomAvEmpAv',
      promise: Vue.axios.post(state.noAccessURL + 'call-month.php', formData)
    }).then((result) => {
      commit('addDatesForMonth', { data: result.data, key: year + '/' + month });
    });
  }
};

const getters: GetterTree<AppointmentState, RootState> = {
  ...multiEntityGetters,
  monthDates: (state) => state.monthDates,
  roomAv: (state) => state.roomAv,
  employeeAv: (state) => state.employeeAv,
  disabledDates: (state) => state.disabledDatesForMonth
};

export const appointmentModule: Module<AppointmentState, RootState> = {
  namespaced,
  state,
  mutations,
  actions,
  getters
};
