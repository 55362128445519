import { multiEntityActions, multiEntityGetters, MultiEntityLoadingStates, multiEntityMutations, multiEntityState } from '../mixins/loading-states';
import _ from 'lodash';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '../types';
import Vue from 'vue';

const namespaced: boolean = true;

interface CustomerState extends MultiEntityLoadingStates {
  noAccessURL: string,
  companyInformation: any,
  companyId: string|number|null,
  slug: string|null,
  activities: string[]|null,
  employees: string[]|null,
  branches: string[]|null,
  holidays: any[]|null,
  acBranches: string[]
}

const state: CustomerState = {
  ...multiEntityState,
  noAccessURL: process.env.VUE_APP_NOACCESSURL,
  companyInformation: null,
  companyId: null,
  slug: null,
  activities: null,
  employees: null,
  branches: null,
  holidays: null,
  acBranches: []
};

const mutations: MutationTree<CustomerState> = {
  ...multiEntityMutations,
  setCompanyInfo (state, companyInformation) {
    state.companyInformation = companyInformation;
  },
  setCompanyId (state, companyId) {
    state.companyId = companyId;
  },
  setSlug (state, slug) {
    state.slug = slug;
  },
  setActivities (state, activities) {
    state.activities = activities;
  },
  setEmployees (state, employees) {
    state.employees = employees;
  },
  setBranches (state, branches) {
    state.branches = branches;
  },
  setHolidays (state, holidays) {
    state.holidays = holidays;
  },
  setActivityBranches (state, ac) {
    state.acBranches = ac;
  }
};

const actions: ActionTree<CustomerState, RootState> = {
  ...multiEntityActions,
  parseSlug ({ dispatch, commit, state }, slug) {
    commit('setSlug', slug);
    return dispatch('handleLoadingState', {
      entity: 'otevUrl',
      promise: Vue.axios.get(state.noAccessURL + 'otevurl.php?otevurl=' + slug)
    }).then((result) => {
      commit('setCompanyId', result.data[0]);
      return dispatch('loadCompanyInformation');
    });
  },
  loadCompanyInformation ({ dispatch, commit, state }) {
    return dispatch('handleLoadingState', {
      entity: 'companyInfo',
      promise: Vue.axios.get(
        state.noAccessURL + 'getAppointment.php?action=personalize&companyId=' + state.companyId
      )
    }).then((result) => {
      commit('setCompanyInfo', result.data[0]);
    });
  },
  loadActivities ({ dispatch, commit, state }) {
    return dispatch('handleLoadingState', {
      entity: 'activities',
      promise: Vue.axios.get(
        state.noAccessURL + 'getAppointment.php?action=activities&companyId=' + state.companyId
      )
    }).then((result) => {
      commit('setActivities', result.data);
    });
  },
  loadBranchesByActivity ({ dispatch, commit, state }, ac) {
    return dispatch('handleLoadingState', {
      entity: 'branchesbyactivity',
      promise: Vue.axios.get(
        state.noAccessURL + 'getAppointment.php?action=branchesbyactivity&companyId=' + state.companyId + '&activityId=' + ac
      )
    }).then((result) => {
      let rr = result.data.map((el: any) => el.BranchID);
      rr = _.uniq(rr);
      commit('setActivityBranches', rr);
    });
  },
  loadEmployees ({ dispatch, commit, state }) {
    return dispatch('handleLoadingState', {
      entity: 'employees',
      promise: Vue.axios.get(
        state.noAccessURL + 'getAppointment.php?action=employees&companyId=' + state.companyId
      )
    }).then((result) => {
      commit('setEmployees', result.data);
    });
  },
  loadBranchActivityEmployees ({ dispatch, commit, state }, { activityId, branchId }) {
    return dispatch('handleLoadingState', {
      entity: 'activityBranchEmployees',
      promise: Vue.axios.get(
        state.noAccessURL +
          'getAppointment.php?action=employeeActivites&companyId=' +
          state.companyId +
          '&activityId=' +
          activityId +
          '&branchId=' +
          branchId
      )
    }).then((result) => {
      const data = result.data;
      data.filter((el: any) => el.EmployeeID === '0').forEach((el: any) => (el.ActivityID = activityId));
      commit('setEmployees', data);
    });
  },
  loadRoomEmployeeTimes ({ dispatch, commit, state }, formData) {
    return dispatch('handleLoadingState', {
      entity: 'RoomAvEmpAv',
      promise: Vue.axios.post(state.noAccessURL + 'call.php', formData)
    }).then((result) => {
      commit('setEmployeeAv', result.data[0]);
      commit('setRoomAv', result.data[1]);
    });
  },
  loadBranches ({ dispatch, commit, state }) {
    return dispatch('handleLoadingState', {
      entity: 'branches',
      promise: Vue.axios.get(
        state.noAccessURL + 'getAppointment.php?action=branches&companyId=' + state.companyId
      )
    }).then((result) => {
      commit('setBranches', result.data);
    });
  },
  loadHolidays ({ dispatch, commit, state }) {
    return dispatch('handleLoadingState', {
      entity: 'holidays',
      promise: Vue.axios.get(state.noAccessURL + 'getHolidays.php?companyId=' + state.companyId)
    }).then((result) => {
      commit('setHolidays', result.data);
    });
  }
};

const getters: GetterTree<CustomerState, RootState> = {
  ...multiEntityGetters,
  companyInformation: (state) => state.companyInformation,
  companyId: (state) => state.companyId,
  slug: (state) => state.slug,
  activities: (state) => state.activities,
  employees: (state) => state.employees,
  branches: (state) => state.branches,
  holidays: (state) => state.holidays,
  acBranches: (state) => state.acBranches
};

export const customerModule: Module<CustomerState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
  getters
};
