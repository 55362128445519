
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'App',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Index',
    // all titles will be injected into this template
    titleTemplate: '%s | Click2Date',
    meta: [{ httpEquiv: 'X-UA-Compatible', content: 'IE=edge,chrome=1' }],
    link: [
      { rel: 'favicon', href: '/static/favicon.ico' }
    ]
  }
})
export default class App extends Vue {}
