import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';

import StoreModules from './store-modules';

import { adminModule } from '@/store/modules/admin';
import { customerModule } from '@/store/modules/customer';
import { appointmentModule } from '@/store/modules/appointment';
import { RootState } from './types';

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  modules: {
    [StoreModules.ADMIN]: adminModule,
    [StoreModules.CUSTOMER]: customerModule,
    [StoreModules.APPOINTMENT]: appointmentModule
  }
};

export default new Vuex.Store<RootState>(store);
